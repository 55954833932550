// Type definition of asset
export class Asset {
    id: number;
    type: AssetType;
    make: string;
    model: string;
    vehicleClass: string;
    year: number;
    colour: string;
    assignee: AssetAssignee;
    identificationMark: string;
    groupName: string;
    alias: string;
    notes: string;
    status: AssetStatus;
    latestEvent?: AssetEvent;
    unit?: AssetUnit;
    assetSettings: AssetSettings;
    engineHours?: EngineHours;

    constructor(props: {
        id: number;
        type: AssetType
        make: string;
        model: string;
        vehicleClass: string;
        year: number;
        colour: string;
        assignee: AssetAssignee;
        identificationMark: string;
        groupName: string;
        alias: string;
        status: AssetStatus;
        latestEvent?: AssetEvent;
        unit?: AssetUnit;
        assetSettings: AssetSettings
        engineHours?: EngineHours;
        notes: string;
    }) {
        this.id = props.id;
        this.type = props.type;
        this.make = props.make;
        this.model = props.model;
        this.vehicleClass = props.vehicleClass;
        this.year = props.year;
        this.colour = props.colour;
        this.assignee = props.assignee;
        this.identificationMark = props.identificationMark;
        this.groupName = props.groupName;
        this.alias = props.alias;
        this.status = props.status;
        this.latestEvent = props.latestEvent;
        this.unit = props.unit;
        this.assetSettings = props.assetSettings;
        this.notes = props.notes;
    }
}

export enum AssetType {
    CAR = "CAR",
    VAN = "VAN",
    TRUCK = "TRUCK",
    PLANT = "PLANT",
    MOTORCYCLE = "MOTORCYCLE",
    GENERATOR = "GENERATOR",
    STATIC = "STATIC",
    EQUIPMENT = "EQUIPMENT",
    TRAILER = "TRAILER",
    ASSET = "ASSET"
}

export enum UnitType {
    X1 = "X1",
    X8 = "X8",
    U1 = "U1",
    CALAMP = "CALAMP",
    QUECLINK = "QUECLINK",
    TELTONIKA = "TELTONIKA"
}

export enum AssetStatus {
    PRIVATE = "PRIVATE",
    INACTIVE = "INACTIVE",
    STOPPED = "STOPPED",
    STATIONARY = "STATIONARY",
    MOVING = "MOVING",
    CHARGING = "CHARGING"
}

export enum AssetClass {
    PLG = 'PLG',
    LCV = 'LCV',
    HGV = 'HGV',
    PSV = 'PSV',
    CDV = 'CDV',
    HCV = 'HCV',
    VDC = 'VDC',
    CAR = 'Car',
    MOPED = 'Moped',
    MOTORCYCLE = 'Motorcycle',
    PICKUP = 'Pickup',
    TRICYCLE = 'Tricycle',
    OTHER = 'Other'
}

export class AssetAssignee {
    name: string;
    mobileNumber: string;

    constructor(props: { name: string, mobileNumber: string; }) {
        this.name = props.name;
        this.mobileNumber = props.mobileNumber;
    }
}


export class AssetSettings {
    driver: string;
    co2Output: number;
    manHour1: number;
    manHour2: number;
    runningCost: number;
    privateCost: number;
    customWorkingHours: boolean;
    weekdayStart: string;
    weekdayEnd: string;
    saturdayStart: string;
    saturdayEnd: string;
    sundayStart: string;
    sundayEnd: string;

    constructor(props: {
        driver: string;
        co2Output: number;
        manHour1: number;
        manHour2: number;
        runningCost: number;
        privateCost: number;
        customWorkingHours: boolean;
        weekdayStart: string;
        weekdayEnd: string;
        saturdayStart: string;
        saturdayEnd: string;
        sundayStart: string;
        sundayEnd: string;
    }) {
        this.driver = props.driver;
        this.co2Output = props.co2Output;
        this.manHour1 = props.manHour1;
        this.manHour2 = props.manHour2;
        this.runningCost = props.runningCost;
        this.privateCost = props.privateCost;
        this.customWorkingHours = props.customWorkingHours;
        this.weekdayStart = props.weekdayStart;
        this.weekdayEnd = props.weekdayEnd;
        this.saturdayStart = props.saturdayStart;
        this.saturdayEnd = props.saturdayEnd;
        this.sundayStart = props.sundayStart;
        this.sundayEnd = props.sundayEnd;
    }
}

export class AssetEvent {
    status: AssetStatus;
    type: String;
    time: Date;
    location: AssetLocation;
    heading: number;
    headingIndicator: String;
    speed: Speed;
    speedLimit: Speed;
    odometer: number;
    customEventName: String;
    customEventId: number;
    fuelLevel: number;
    wasteLevel: number;
    temperature1?: number;
    temperature2?: number;
    batteryPercentage?: number;


    constructor(props: {
        status: AssetStatus;
        type: String;
        time: Date;
        location: AssetLocation;
        heading: number;
        headingIndicator: String;
        speed: Speed;
        speedLimit: Speed;
        odometer: number;
        customEventName: String;
        customEventId: number;
        fuelLevel: number;
        wasteLevel: number;
        temperature1?: number;
        temperature2?: number;
        batteryPercentage?: number;
    }) {
        this.status = props.status;
        this.type = props.type;
        this.time = props.time;
        this.location = props.location;
        this.heading = props.heading;
        this.headingIndicator = props.headingIndicator
        this.speed = props.speed
        this.speedLimit = props.speedLimit
        this.odometer = props.odometer
        this.customEventName = props.customEventName;
        this.customEventId = props.customEventId;
        this.fuelLevel = props.fuelLevel;
        this.wasteLevel = props.wasteLevel;
        this.temperature1 = props.temperature1;
        this.temperature2 = props.temperature2;
        this.batteryPercentage = props.batteryPercentage;
    }
}

export class AssetUnit {
    type: UnitType;
    model: String;
    imei: String;
    simNumber: String;
    voiceNumber: String;
    dataNumber: String;

    constructor(props: {
        type: UnitType;
        model: String;
        imei: String;
        simNumber: String;
        voiceNumber: String;
        dataNumber: String;
    }) {
        this.type = props.type;
        this.model = props.model;
        this.imei = props.imei;
        this.simNumber = props.simNumber;
        this.voiceNumber = props.voiceNumber;
        this.dataNumber = props.dataNumber;
    }
}

export class AssetLocation {
    goodGpsFix: boolean;
    rawCoordinates: Coordinates;
    snappedCoordinates: Coordinates;
    formattedAddress: String;
    zoneName: string;
    geofences: GeofenceStatus[]

    constructor(props: {
        goodGpsFix: boolean, rawCoordinates: Coordinates; snappedCoordinates: Coordinates;
        formattedAddress: String; zoneName: string, geofences: GeofenceStatus[]
    }) {
        this.goodGpsFix = props.goodGpsFix;
        this.rawCoordinates = props.rawCoordinates;
        this.snappedCoordinates = props.snappedCoordinates;
        this.formattedAddress = props.formattedAddress;
        this.zoneName = props.zoneName;
        this.geofences = props.geofences;
    }
}

export class Coordinates {
    latitude: number;
    longitude: number;

    constructor(props: { latitude: number; longitude: number }) {
        this.latitude = props.latitude;
        this.longitude = props.longitude;
    }
}

export class GeofenceStatus {
    geofenceName: string;
    enterTime: Date;

    constructor(props: { geofenceName: string; enterTime: Date }) {
        this.geofenceName = props.geofenceName;
        this.enterTime = props.enterTime;
    }
}

export enum SpeedUnits {
    KPH = "Kph",
    MPH = "Mph"
}

export class Speed {
    value: number;
    units: SpeedUnits;

    constructor(props: { value: number; units: SpeedUnits }) {
        this.value = props.value;
        this.units = props.units;
    }
}

export class EngineHours {
    vehicleId: number;
    enabled: boolean;
    engineTimeHours: number;

    constructor({ vehicleId, enabled, engineTimeHours }: { vehicleId: number, enabled: boolean, engineTimeHours: number }) {
        this.vehicleId = vehicleId;
        this.enabled = enabled;
        this.engineTimeHours = engineTimeHours;
    }
}
